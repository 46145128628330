import ApiUtils from "../../helpers/APIUtils";

const api = new ApiUtils();

const TOKEN_NAME = "id_token";

export const loadUser = () => async (dispatch) => {
  try {
    const token = localStorage.getItem(TOKEN_NAME);

    if (!token) return dispatch({ type: "NEW_USER" });

    const res = await api.loadUser({ Authorization: `Bearer ${token}` });

    dispatch({
      type: "EXISTING_USER",
      payload: res.data.data,
      token,
    });

    return true;
  } catch (err) {
    dispatch({ type: "AUTH_FAILED" });
    return false;
  }
};

export const login = (data) => async (dispatch) => {
  try {
    const res = await api.login(data);
    // after successfully login, you will get token on it
    localStorage.setItem(TOKEN_NAME, res?.data?.access_token);

    await dispatch(loadUser(res?.data?.access_token));

    return true;
  } catch (err) {
    console.log("error");
    dispatch({ type: "AUTH_FAILED" });
    return false;
  }
};

export const logout = () => async (dispatch) => {
  try {
    const res = await api.logout();
    localStorage.removeItem(TOKEN_NAME);
    dispatch({ type: "LOGOUT" });

    return true;
  } catch (err) {
    console.log("CATCH ERROR", err);
    return false;
  }
};
