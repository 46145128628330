import axios from 'axios';
import store from '../redux/store';
import { logout } from '../redux/actions/authActions';

class ApiUtils {
  constructor(request = true, appendAuth = true, response = true) {
    this.axios = axios.create({
      baseURL: 'https://api.vizorads.com/api/v1',
    });

    if (request) {
      this.axios.interceptors.request.use(
        config => {
          const myConfig = { ...config };
          if (appendAuth) {
            const { auth } = store.getState();
            if (auth.isAuthenticated) myConfig.headers.Authorization = `Bearer ${auth.token}`;
          }
          console.debug('Request', config);
          return myConfig;
        },
        error => {
          console.debug('Request Error', error);
          console.debug('Request Failed', error.request.data.message);
          return Promise.reject(error);
        }
      );
    }

    if (response) {
      this.axios.interceptors.response.use(
        config => {
          const myConfig = { ...config };
          console.debug('Response', myConfig);

          return myConfig;
        },
        error => {
          if (error.response.data.status === 401 || error.response.data.status === 403) {
            const { auth } = store.getState();
            //   if (auth.token) {
            //     store.dispatch(logout());
            //     setTimeout(() => window.location.assign("/login"), 1000);
            //   }
          } else {
            console.debug('3');
          }
          console.debug('Response Error', error);
          console.debug('Response Error', error.response.data.status);
          return Promise.reject(error);
        }
      );
    }
  }

  loadUser = headers =>
    this.axios({
      method: 'POST',
      url: '/profile',
      // FOR LOCAL url: "/api/users/:id" ( dont forget to change response in loadUser )
      headers: {
        ...headers,
        'X-RapidAPI-Host': 'vizor-ads.p.rapidapi.com',
        'X-RapidAPI-Key': '9846b8bf51mshf1d65a505b76988p188f2ajsn7136afeaa49f',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
    });

  login = data =>
    this.axios({
      method: 'POST',
      url: '/login',
      // FOR LOCAL url: "/api/users/:id" ( dont forget to change response in loadUser )
      data,
      headers: {
        'X-RapidAPI-Host': 'vizor-ads.p.rapidapi.com',
        'X-RapidAPI-Key': '9846b8bf51mshf1d65a505b76988p188f2ajsn7136afeaa49f',

        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
    });

  logout = () =>
    this.axios({
      method: 'POST',
      url: '/profile/logout',
      headers: {
        'X-RapidAPI-Host': 'vizor-ads.p.rapidapi.com',
        'X-RapidAPI-Key': '9846b8bf51mshf1d65a505b76988p188f2ajsn7136afeaa49f',

        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
    });

  getDashboard = data =>
    this.axios({
      method: 'POST',
      url: '/user/dashboard',
      headers: {
        'X-RapidAPI-Host': 'vizor-ads.p.rapidapi.com',
        'X-RapidAPI-Key': '9846b8bf51mshf1d65a505b76988p188f2ajsn7136afeaa49f',

        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
      data,
    });

  getMetaHash = slug =>
    this.axios({
      method: 'POST',
      url: `/nft/metahash/${slug}`,
      headers: {
        'X-RapidAPI-Host': 'vizor-ads.p.rapidapi.com',
        'X-RapidAPI-Key': '9846b8bf51mshf1d65a505b76988p188f2ajsn7136afeaa49f',

        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
    });

  saveNftAddress = (slug, metaHash) =>
    this.axios({
      method: 'POST',
      url: `/nft/save/${slug}/${metaHash}`,
      headers: {
        'X-RapidAPI-Host': 'vizor-ads.p.rapidapi.com',
        'X-RapidAPI-Key': '9846b8bf51mshf1d65a505b76988p188f2ajsn7136afeaa49f',

        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
    });
}

export default ApiUtils;
