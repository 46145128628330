const initialState = {
  isAuthenticated: false,
  token: null,
  user: {},
  language: "en",
};

const AuthReducer = (state = initialState, action) => {
  const { type, payload, token } = action;

  switch (type) {
    case "EXISTING_USER":
      return {
        ...state,
        isAuthenticated: true,
        token,
        user: payload,
      };
    case "CHANGE_LANGUAGE":
      return {
        ...state,
        language: payload,
      };
    case "NEW_USER":
    case "AUTH_FAILED":
      return initialState;
    default:
      return state;
  }
};

export default AuthReducer;
